import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ContactComponent } from "./contact/contact.component";
import { SkillsComponent } from "./skills/skills.component";

const routes: Routes = [
  /*
  {
    path: "realisations",
    component: GalleryComponent,
    data: { animation: "GalleryPage" }
  },
  */
  {
    path: "competences",
    component: SkillsComponent,
    data: { animation: "Skills" }
  },
  {
    path: "contact",
    component: ContactComponent,
    data: { animation: "Contact" }
  },
  { path: "accueil", component: HomeComponent, data: { animation: "Home" } },
  { path: '', pathMatch: "full", redirectTo: '/accueil' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
