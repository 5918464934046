import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-skills",
  templateUrl: "./skills.component.html",
  styleUrls: ["./skills.component.scss"]
})
export class SkillsComponent implements OnInit {
  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.meta.addTags([
      {
        name: "description",
        content:
          "Mes compétences techniques de développeur web et les outils que j'utilise au quotidien sont affichés ici."
      },
      {
        name: "keywords",
        content:
          "HTML5, CSS3, Angular8, PHP7, Symfony4, Symfony5, Laravel5, Laravel6, JavaScript, TypeScript, NodeJS, ElectronJS, Git, Sass, Web design, web development, développeur web, compétences, skills"
      }
    ]);

    this.translate.stream("BROWSERTITLE.SKILLS").subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }
}
