import { Component, OnInit, OnDestroy } from '@angular/core';
import Typed from 'typed.js';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

declare var particlesJS: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {

  subTitleFirst: string;
  subTitleSecond: string;
  translateSubscription: Subscription;
  constructor(
    private translate: TranslateService,
    private titleService: Title) {
  }

  ngOnInit() {
    this.translate.stream('BROWSERTITLE.HOME').subscribe(
      (text: string) => {
        this.titleService.setTitle(text);
      }
    )

    /*
    let translateObservable = this.translate.stream(['HOMEPAGE.SUBTITLE_FIRST', 'HOMEPAGE.SUBTITLE_SECOND']);
    this.translateSubscription = translateObservable.subscribe(
      (translations: string) => {
        this.subTitleFirst = translations['HOMEPAGE.SUBTITLE_FIRST'];
        this.subTitleSecond = translations['HOMEPAGE.SUBTITLE_SECOND'];
      }
    );
    */

    particlesJS.load('particles-js', 'assets/js/particles.json');
    /*
    new Typed(".homepage__subtitle", {
      strings: [this.subTitleFirst, this.subTitleSecond],
      typeSpeed: 80,
      loop: true
    });
    */
  }

  ngOnDestroy() {
    //this.translateSubscription.unsubscribe();
  }
}
