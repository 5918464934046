import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class ContactService {
    constructor(private httpClient: HttpClient) { }

    sendEmail(email: string, subject: string, message: string): Observable<Object> {
        return this.httpClient.post('https://api.alex-chesnay.fr/sendMail', { email, subject, message });
    }
}