import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { FormGroup, Validators, FormControl } from "@angular/forms";
import { ContactService } from "../services/contact.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  isComplete: boolean = false;
  hasError: boolean = false;
  isAvailable: boolean = true;

  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private contactService: ContactService,
    private meta: Meta
  ) { }

  ngOnInit() {
    this.meta.addTags([
      {
        name: "description",
        content:
          "Vous recherchez un développeur web ? ça tombe bien j'en suis un! Contacter moi dès maintenant afin de concrétiser vos projets web."
      },
      {
        name: "keywords",
        content:
          "HTML5, CSS3, Angular8, PHP7, Symfony4, Symfony5, Laravel5, Laravel6, JavaScript, TypeScript, NodeJS, ElectronJS, Git, Sass, Web design, web development, développeur web, contact me, contactez moi, email, réseaux sociaux, social medias, dribbble, deviantart, discordapp, github"
      }
    ]);

    this.contactForm = new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      subject: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(200)
      ]),
      message: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(20000)
      ])
    });
    this.translate.stream("BROWSERTITLE.CONTACT").subscribe((text: string) => {
      this.titleService.setTitle(text);
    });
  }

  get email() {
    return this.contactForm.get("email");
  }

  get subject() {
    return this.contactForm.get("subject");
  }

  get message() {
    return this.contactForm.get("message");
  }

  onSubmitForm() {
    const formValue = this.contactForm.value;
    const email = formValue["email"];
    const subject = formValue["subject"];
    const message = formValue["message"];
    this.contactService.sendEmail(email, subject, message)
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.isComplete = true;
            this.hasError = false;
            this.contactForm.reset();
            setTimeout(() => {
              this.isComplete = false;
            }, 7000)
          } else {
            console.log(res);
            this.hasError = true;
            this.isComplete = false;
            setTimeout(() => {
              this.hasError = false;
            }, 10000)
          }
        },
        err => {
          console.error(err);
          this.hasError = true;
          this.isComplete = false;
          setTimeout(() => {
            this.hasError = false;
          }, 10000)
        }
      );
  }
}
