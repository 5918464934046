import { Component } from "@angular/core";
import { RouterOutlet, Router } from "@angular/router";
import { slider, fader } from "./route-animations";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [fader]
})
export class AppComponent {
  constructor(translate: TranslateService, private router: Router) {
    translate.addLangs(["fr", "en"]);
    translate.setDefaultLang("fr");
    const browserLang = navigator.language.substr(0, 2);
    translate.use(browserLang.match('en|fr') ? browserLang : 'fr');
  }

  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData["animation"]
    );
  }
}
