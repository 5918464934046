import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private titleService: Title, private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.stream('BROWSERTITLE.GALLERY').subscribe(
      (text: string) => {
        this.titleService.setTitle(text);
      }
    )
  }

}
